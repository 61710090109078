import { useAppContext } from 'context/AppContext'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useRef, useState } from 'react'
import css from './HomeSearchBox.module.scss'
import { useMediaQuery } from 'react-responsive'
import Loader from '../../Loader'
import TabButtonHomePage from '../TabButtonHomePage/TabButtonHomePage'
import HomeSearchCity from '../HomeSearchCity'
import HomeSearchDistrict from '../HomeSearchDistrict'
import HomeSearchPropertyTypeDropDownBox from '../HomeSearchPropertyTypeDropDownBox'
import { useRouter } from 'next/router'
import { commercialAndResidentialPropertyTypeOptions } from '@/utils/constant'
import { pushDataLayerEventHandler } from '@/utils/utils'

export default function HomeSearchBox(props) {
  const {
    handlePropertyIdChange,
    changePropertyType,
    setInputValue,
    setInputError,
    setShowSuggestion,
    inputError,
    selectedCity,
    onSearch} = props;
  const { t, lang } = useTranslation('translations')
  const { state, dispatch } = useAppContext()
  const { searchReducer, homeSearchReducer } = state
  const router = useRouter()
  const buttonRef = useRef()
  const isDesktop = useMediaQuery({ minWidth: 767 })
  const selectedDistricts = homeSearchReducer?.selectedDistrictsMsite
  const [isCommercial,setIsCommercial] = useState(searchReducer?.propertyType==='commercial' || false)
  const [isSearching,setIsSearching] = useState(false)
  const [isSetToSale,setIsSetToSale] = useState(false)

  useEffect(() => {
    dispatch({ type: 'UPDATE_PROPERTYFOR', payload: 'sale' })
    dispatch({ type: 'UPDATE_PROPERTYTYPE', payload: 'residential' })
    return () =>resetAllOnSearch()
  }, [])

  useEffect(() => {
    if (isDesktop) {
      const checkIfClickedOutside = (e) => {
        if (inputError && buttonRef.current && !buttonRef.current.contains(e.target)) {
          setInputError(false)
          dispatch({ type: 'SET_IS_OUTSIDE_CLICK', payload: false })
        }
      }
      document.addEventListener('click', checkIfClickedOutside)
    }
  }, [inputError])
  const resetAllOnSearch = () =>{
    setInputValue('')
    selectedCity({});
    dispatch({type:"REMOVE_ALL_SELECTED_DISTRICT",payload: []});
    dispatch({ type: "SET_ALL_DISTRICTS", payload: [] })
    setIsSetToSale(true);
    handlePropertyTypeSelection('residential',true)
  }

  const handlePropertyTypeSelection = (tabname,isClearAll=false) =>{
    setIsCommercial(tabname==='commercial')
    dispatch({type:'UPDATE_PROPERTYTYPE_MSITE',payload:tabname})
    dispatch({type:'UPDATE_PROPERTYTYPE',payload:tabname})
    const mapView = JSON.parse(localStorage.getItem('zonefilterClick'))
    const isHomePage = router.pathname === '/'
    const isMapView = mapView?.isMapView || false
    const isMapOrList = isMapView ? 'srp_map' : 'srp_list'
    const page = isHomePage ? 'Home Page' : isMapOrList
    const data = {
      status: tabname === 'commercial',
      placement: "search_screen",
      change_to: tabname,
    }
    const selectedType = searchReducer?.propertyType == 'residential' ? 'commercial' : 'residential'
    dispatch({type:"ALL_PROPERTY_TYPES", payload:commercialAndResidentialPropertyTypeOptions(t)?.[isClearAll ? 'residential' : selectedType]})
   !isClearAll && pushDataLayerEventHandler(window, lang, 'view_commercial_prop', page, data)
  }
  const handleSearch = () =>{
    onSearch && onSearch(searchReducer?.selectedItem?.countryName && searchReducer?.selectedItem?.countryName?.length!==0 && searchReducer?.selectedItem?.countryName[0]? searchReducer?.selectedItem : {cityId:273},{districtValue:selectedDistricts,propertyType:homeSearchReducer?.allPropertyTypes}); 
    setIsSearching(true);
    let cityData={}
  if(searchReducer?.selectedItem?.countryName && searchReducer?.selectedItem?.countryName?.length!==0 && searchReducer?.selectedItem?.countryName[0]){
    cityData={
      city_id: searchReducer?.selectedItem?.cityId[0] || 273,
      city: searchReducer?.selectedItem?.countryName[0] || t('Home.RIYADH'),
      popular_city: searchReducer?.selectedItem?.popularCity ?? true
    }
  } else{
      cityData={
        city_id: 273,
        city: t('Home.RIYADH'),
        popular_city: true
      }
    }
    let districtData={district:[],districtId:[]}
    if(selectedDistricts && selectedDistricts?.length!=0){
      selectedDistricts?.map((val)=>{
        districtData={
          district:[...districtData?.district,val?.title],
          districtId:[...districtData?.districtId,val?.districtId]
        }
      })
    }
    const allSelectedPropertyTypeName = homeSearchReducer?.allPropertyTypes ? String(homeSearchReducer?.allPropertyTypes?.filter((value)=>value.isSelected)?.map((obj)=>obj?.name)) : '' //Selected properties name
    const data ={
      ...cityData,
      district: districtData && districtData?.district && districtData?.district?.length!==0 ? String(districtData?.district) : '',
      district_id: districtData && districtData?.districtId && districtData?.districtId?.length!==0 ? String(districtData?.districtId) : '',
      service: searchReducer?.propertyFor || 'sale',
      property_type: allSelectedPropertyTypeName,
      view_commercial_prop:isCommercial,
      nearby_district : selectedDistricts?.find((district)=>district?.regionId && district?.locationType && district?.distance ) ? true : false , // distance present in nearby districts
      popular_district : (selectedDistricts?.find((district)=>!district?.distance ) && selectedDistricts[0]?.locationType !== 'city') ?? false // distance not presnt in popular
    }
    if(allSelectedPropertyTypeName && allSelectedPropertyTypeName!==''){
      const propertyData={
        property_type: allSelectedPropertyTypeName, 
        placement:'Home Page'
      }
      pushDataLayerEventHandler(window, lang, 'property_type_selected','Home Page', propertyData)
    }
    pushDataLayerEventHandler(window, lang, 'search', 'Home Page', data)
  }
  return (
    <>
     <TabButtonHomePage 
          setShowSuggestion={setShowSuggestion}
          defaultTab={'sale'}
          handlePropertyIdChange={handlePropertyIdChange}
          changePropertyType={changePropertyType}
          isSetToSale={isSetToSale}
        />
        <div className={css.SearchWrapper}>
          <HomeSearchCity {...props}/>
          <HomeSearchDistrict {...props}/>
          <HomeSearchPropertyTypeDropDownBox/>
          <div className={css.buttonWrap}>
            <button id = 'searchButton' ref={buttonRef} className={css.searchButtonText} onClick={()=> handleSearch()}>{isSearching ? <Loader/> : t('Home.SEARCH')}</button>
          </div>
        </div>
       
        <div className={css.commercialTab}>
            <input id = 'commercialCheckbox' className={css.checkbox} type='checkbox' checked={isCommercial} onChange={()=>handlePropertyTypeSelection(!isCommercial ? 'commercial':'residential')}/>
            <span className={css.checkmark} onClick={()=>handlePropertyTypeSelection(!isCommercial ? 'commercial':'residential')}></span>
            <label>{t("NewPropertySearch.VIEW_COMMERCIAL_PROP_ONLY")}</label>
        </div>
       
    </>
  )
}
