import React, { memo, useEffect, useRef, useState } from 'react'
import IconoGraphy from '../../IconoGraphy'
import DistrictSearch from './DistrictSearch'
import { useAppContext } from 'context/AppContext'
import css from './desktopHomeDistrictSearch.module.scss'
import useTranslation from 'next-translate/useTranslation'
import { getAutoSuggestData } from 'services/cityOptionsService'

function HomeSearchDistrict(props) {
  const { t , lang } = useTranslation('translations')
  const { state, dispatch } = useAppContext()
  const { searchReducer, homeSearchReducer } = state
    const [isDistrictOpen,setIsDistrictOpen] = useState(false)
    const [districtSearch,setDistrictSearch] = useState('')
    const [showAllDistrictsData, setShowAllDistrictsData] = useState()
    const [noPopularDistrict, setNoPopularDistrict] = useState(false)
    const dropdownMaxRef = useRef()
    const selectedDistricts = homeSearchReducer?.selectedDistrictsMsite
    const { showAllClicked } = homeSearchReducer
    useEffect(()=>{
        const checkIfClickedOutside = (e) => {
          if ( isDistrictOpen && dropdownMaxRef.current && !dropdownMaxRef.current.contains(e.target)) {
            setIsDistrictOpen(false)
            dispatch({ type: "SET_SHOW_ALL_CLICKED", payload: false })
          }
        }
        document.addEventListener('mousedown', checkIfClickedOutside)
        return () => {
          document.removeEventListener('mousedown', checkIfClickedOutside)
        }
      },[isDistrictOpen])

      const fetchAllDistricts = async () => {
        let payload = {
          "newAutoSuggest": true,
          "propertyFor": searchReducer?.propertyForMsite,
          "cityId": !searchReducer?.selectedItem?.cityId || searchReducer?.selectedItem?.cityId?.length == 0 ? 273 : searchReducer?.selectedItem?.cityId[0] || 273,
        }
        const reqHeaders = {
          'Content-Type': 'application/json;charset=UTF-8',
          locale: lang,
        }
        const data = await getAutoSuggestData(reqHeaders, payload)
        if(data && (Array.isArray(data) && data?.length!==0 || !Array.isArray(data) && Object.keys(data).length !== 0) && data?.allCities?.length!==0){
          dispatch({ type: "SET_ALL_DISTRICTS", payload: data })
          setShowAllDistrictsData(data);
          setNoPopularDistrict(false)
        }
        if((data?.allCities && data?.allCities?.length==0) || !data){
          dispatch({ type: "SET_ALL_DISTRICTS", payload: [] })
          setShowAllDistrictsData([])
          setNoPopularDistrict(true)
        }
      }
      useEffect(()=>{
        setDistrictSearch('')
        if(!props?.isMobileSSR){
          fetchAllDistricts();
        }
      },[searchReducer?.selectedItem?.countryName])
    const handleDistrictClick = () =>{
      setIsDistrictOpen(true)
      document && document.getElementById("districtSearch") && document.getElementById("districtSearch").focus();
    }
    const handleDistrictSearch = (e) =>{
      setDistrictSearch(e.target.value.trimStart())
     if(showAllClicked){
      dispatch({ type: "SET_ALL_DISTRICTS", payload: [] })
      dispatch({ type: "SET_SHOW_ALL_CLICKED", payload: false })
     } 
    }
    const districtPlaceholder = () =>{
      if(isDistrictOpen || selectedDistricts?.length==0){
        return `${t("NewPropertySearch.SEARCH_DISTRICTS_IN")} ${searchReducer?.selectedItem?.countryName[0] || t('Home.RIYADH')}`
      }
      else if(selectedDistricts?.length < 5) {
        return t('Home.ADD_DISTRICT')
      }
      return ''
    } 
    return (
        <div ref={dropdownMaxRef} className={css.distSearchWrapper}>
          <div className={css.innerWrap} onClick={handleDistrictClick}>
            <label>{t('NewPropertySearch.DISTRICT')}</label>
            <div className={css.distSearchWrap}>
              {selectedDistricts?.length!==0 && !isDistrictOpen && 
                <div className={css.distSelected}>
                  <button className={css.btn}>
                    {selectedDistricts[0]?.srpTitle}
                    <IconoGraphy
                      iconClass='icon-close'
                      iconColor='color_purple'
                      fontSize='f_7'
                      onClickHandler={()=>dispatch({type:"REMOVED_DISTRICT",payload: selectedDistricts[0]})}
                    />
                  </button>
                  {selectedDistricts?.length>1 && <button className={css.length}>{lang == 'en' ? '+' : ''}{selectedDistricts?.length-1}{lang == 'ar' ? '+' : ''}</button> }
                </div>
              }
              <input id="districtSearch" placeholder={districtPlaceholder()} value={districtSearch ?? ''} onChange={(e)=>handleDistrictSearch(e)} onFocus={handleDistrictClick} className={css.searchDist}/>
            </div>
          </div>
          <div ref={dropdownMaxRef} className={css.suggestionSection}>
            {isDistrictOpen && <DistrictSearch {...props} isFromDesktop = {true} districtSearchDesktop={districtSearch} setInputValueDesktop={setDistrictSearch} showAllDistrictsDataDesktop={showAllDistrictsData} noPopularDistrict={noPopularDistrict} />}
          </div>
        </div>
    )
}

export default memo(HomeSearchDistrict)