import { useAppContext } from 'context/AppContext'
import React, { memo } from 'react'
import styles from './styles.module.scss'

export const AllCities = ({
  title,
  allCities,
  selectedCity,
  onSearch = null,
  isHomeSearchMobile = false,
  toggleCityBackClick,
  isFromHomeDesk = false,
  isFromSRPDesk = false,
  setShouldShowExceedLimitMsg = null,
}) => {
  const { state, dispatch } = useAppContext()
  const {
    searchReducer: { selectedItem },
  } = state

  const handleHomePageCitySelection = () => {
    dispatch({ type: 'REMOVE_ALL_SELECTED_DISTRICT', payload: [] })
    dispatch({ type: 'SET_ALL_DISTRICTS', payload: [] })
    toggleCityBackClick && toggleCityBackClick()
  }
  return (
    <div
      className={`${styles.suggestion_wrap} ${styles.mt16} ${isHomeSearchMobile ? styles.mobileTopLocation : ''} ${
        isFromSRPDesk ? styles.srpDesktopWrap : ''
      }`}
    >
      <div className={styles.scrollber}>
        {title && <p className={styles.suggestion}>{title}</p>}
        <div
          id='cityList'
          className={`${isHomeSearchMobile ? styles.topCityButtonWrapper : ''} ${isFromSRPDesk ? styles.filters : ''}`}
        >
          {allCities?.map((val, i) => (
            <button
              className={`${styles.suggestion_list} ${isFromHomeDesk ? styles.suggestionListings : ''} ${
                isFromHomeDesk &&
                (val?.title === selectedItem?.countryName[0] || val?.title === selectedItem?.cityName[0])
                  ? styles.selectedCityName
                  : ''
              }`}
              onClick={() => {
                dispatch({ type: 'REMOVED_PILL_COUNTRY', payload: false })
                dispatch({ type: 'REMOVED_PILL_CITY', payload: false })
                dispatch({ type: 'REMOVED_PILL_DISTRICT', payload: false })
                dispatch({ type: 'RECENT_SEARCH_VALUE', payload: null })
                selectedCity(val)
                setShouldShowExceedLimitMsg && setShouldShowExceedLimitMsg(true)
                !isHomeSearchMobile && !isFromHomeDesk ? onSearch && onSearch(val) : handleHomePageCitySelection()
              }}
              key={i}
            >
              <div>
                <div className={styles.propertyTypeWrap}>
                  <span className={styles.cityName}>{isFromSRPDesk ? val?.srpTitle || val?.title : val?.title}</span>
                  {val?.distanceString && !isFromSRPDesk && <p className={styles.propertyType}>{val?.distanceString}</p>}
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default memo(AllCities)
