import React, { memo, useEffect, useRef, useState } from 'react'
import { useAppContext } from 'context/AppContext'
import { capitalizeFirstLetter } from '@/utils/utils'
import { commercialAndResidentialPropertyTypeOptions } from '@/utils/constant'
import useTranslation from 'next-translate/useTranslation'
import css from './searchPropertyDesktop.module.scss'

function HomeSearchPropertyTypeBox() {
    const { state, dispatch } = useAppContext()
    const { t, lang } = useTranslation('translations')
    const {searchReducer, homeSearchReducer } = state
    const [isPropertyTypeOpen,setIsPropertyTypeOpen] = useState(false)
    const dropdownMaxRef = useRef()
    const {allPropertyTypes} = homeSearchReducer
    useEffect(()=>{
      if(allPropertyTypes && allPropertyTypes?.length!==0){
        if(allPropertyTypes[0].whichType!==searchReducer?.propertyType){
            dispatch({type:"ALL_PROPERTY_TYPES", payload:commercialAndResidentialPropertyTypeOptions(t)?.[searchReducer?.propertyType || 'residential']})
        }
      }
        else{
          dispatch({type:"ALL_PROPERTY_TYPES", payload:commercialAndResidentialPropertyTypeOptions(t)?.[searchReducer?.propertyType || 'residential']})
        }
      
    },[])

    useEffect(()=>{
        const checkIfClickedOutside = (e) => {
          if ( isPropertyTypeOpen && dropdownMaxRef.current && !dropdownMaxRef.current.contains(e.target)) {
            setIsPropertyTypeOpen(false)
          }
        }
        document.addEventListener('mousedown', checkIfClickedOutside)
        return () => {
          document.removeEventListener('mousedown', checkIfClickedOutside)
        }
      },[isPropertyTypeOpen])
    const handlePropertyTypeClick = (e) =>{
      e?.preventDefault()
      setIsPropertyTypeOpen(!isPropertyTypeOpen)
    }
    const handlePropertyTypeSelect = (e, index) =>{
        e.stopPropagation()
        e.preventDefault();
        let tempPropType = [...homeSearchReducer?.allPropertyTypes];
        tempPropType[index].isSelected = !tempPropType[index].isSelected;
        dispatch({type:"ALL_PROPERTY_TYPES", payload:tempPropType})
      }
      const selectedInputValuesText = () =>{
        if(!allPropertyTypes || allPropertyTypes?.length==0) return ''
        const selectedPropertyTypes = allPropertyTypes?.filter((value)=>value?.isSelected);
        if(!selectedPropertyTypes || (selectedPropertyTypes && selectedPropertyTypes?.length==0)) {
          return ''
        }
        else if(selectedPropertyTypes && selectedPropertyTypes?.length==1) {
          return `${selectedPropertyTypes?.length} ${t('NewPropertySearch.PROPERTY_TYPE')}` 
        }
        return `${selectedPropertyTypes?.length} ${t('NewPropertySearch.PROPERTY_TYPES')}`
      }
    return (
      <div ref={dropdownMaxRef} className={css.searchPropertyWrapper}>
        <div className={css.innerWrap} onClick={(e) => handlePropertyTypeClick(e)}>
          <label>{t('NewPropertySearch.PROPERTY_TYPE')}</label>
          <input id="propertyTypeInput" placeholder={t('NewPropertySearch.ALL_TYPES')} value={selectedInputValuesText()} className={css.searchProperty} readOnly={true}/>
        </div>
        <div className={css.suggestionSection}>
          {isPropertyTypeOpen && <div className={css.scrollDiv}><div className={css.scrollber}>
            <label>{t('NewPropertySearch.PROPERTY_TYPE_HEADING')}</label>
            <ul id = "propertyTypes">
              {allPropertyTypes && allPropertyTypes?.map((obj, index) => <>
                <li onClick={(e) => handlePropertyTypeSelect(e, index)}><button id = {index} type='button' className={obj.isSelected ? css.active:''}>{lang === 'en' ? capitalizeFirstLetter(obj?.title?.toLowerCase() ?? '') : obj?.title ?? ''}</button></li>
              </>)}
            </ul> </div></div>}
        </div>
      </div>
    )
}

export default memo(HomeSearchPropertyTypeBox)