import React, { useEffect, useState } from "react";
import css from './tabbuttonHomePage.module.scss'
import useTranslation from 'next-translate/useTranslation'
import { useAppContext } from "context/AppContext";
import { useRouter } from "next/router";
import { pushDataLayerEventHandler } from "@/utils/utils";

export default function TabButtonHomePage ({ defaultTab, handlePropertyIdChange, changePropertyType,setTabChangeDetected,tabChangeDetected,isSetToSale = false }) {
    const { t,lang } = useTranslation('translations')
    const {state,dispatch} = useAppContext();
    const {searchReducer}= state;
    const router = useRouter()
    const [selectedTab, setSelectedTab] = useState(searchReducer?.propertyFor)

    useEffect(()=>{
      if(isSetToSale){
        actionFunction('sale')
      }
    },[isSetToSale])

    const actionFunction = (tabname,selectTab)=>{
        let viewClass = 'srp_list';       
        setSelectedTab(tabname)
        handlePropertyIdChange(null,tabname)
        changePropertyType(tabname === defaultTab ? 'sale' : 'rent')
        dispatch({type:'UPDATE_PROPERTYFOR_MSITE',payload:tabname})
        dispatch({type:'UPDATE_PROPERTYFOR',payload:tabname})
        if(setTabChangeDetected && selectTab!==tabname ){
          setTabChangeDetected(!tabChangeDetected)
        }
        const mapView = JSON.parse(localStorage.getItem('zonefilterClick'))

        if (mapView?.isMapView) {
          viewClass = 'srp_map';
        } 
        const page = router.pathname === '/' ? 'Home Page' : viewClass
        const data = {
          service: tabname,
          placement: "Home page",
        }
        pushDataLayerEventHandler(window, lang, 'Change Service', page, data)
      }
      
    return(
      <ul className={css.SearchTab}>
          <li id="saleTab"  className={searchReducer?.propertyFor === 'sale' && css.active }  onClick={() => {actionFunction('sale',selectedTab);}}>{t('Home.BUY')}</li>
          <li id="rentTab" className={searchReducer?.propertyFor === 'rent' && css.active} onClick={() => {actionFunction('rent',selectedTab);}}>{t('Home.RENT')}</li>
      </ul>
    )
}
